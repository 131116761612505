import { formatNumber } from "@app/utils/format-number";
import useSWR from "swr";
import styles from "./index.module.css";

export const AllowanceUsed = () => {
	const { data, isLoading, error } = useSWR<{
		total_allowance: number;
		total_allowance_used: number;
	}>("allowance_summary/");

	console.log(data);
	if (isLoading || error || !data) return null;
	return (
		<div className={styles.container}>
			<strong>Allowance used this year:</strong>{" "}
			{formatNumber(data.total_allowance_used, {
				groupSeparator: ",",
				currencyCode: "R ",
			})}{" "}
			/{" "}
			{formatNumber(data.total_allowance, {
				groupSeparator: ",",
				currencyCode: "R ",
			})}
		</div>
	);
};
